import _ from 'lodash';

const CATEGS = {
  0: 'Thematic',
  1: 'Sub thematic',
  2: 'Sub sub thematic',
};

export function transformCriteriaKeysToOptions(
  criteriaKeys = [],
  isNestedType,
  t,
) {
  // IF FALSE === CONFLICT -> WE NEED TO KEEEP FALSE IN THIS CASE
  if (criteriaKeys === false) return false;
  const result = _.flatten(
    criteriaKeys.map(criteriaKey =>
      criteriaKey.values.map(value => ({
        option: {
          id: value,
          label: value,
          ...(isNestedType && { type: 'normal' }),
        },
        parents: isNestedType ? [t('Other filters'), criteriaKey.name] : [],
        ...(!isNestedType && { filterCategory: criteriaKey.name }),
      })),
    ),
  );
  return !_.isEmpty(result) ? result : null;
}

export function constructThematicLevel(
  projectThematics,
  parentsArray,
  depth = -1,
) {
  if (!projectThematics) return;
  const id = parentsArray[0];
  const currentDepth = depth + 1;

  const relevantThematic = _.find(
    projectThematics,
    projectThematic => projectThematic.id === id,
  );
  const currentThematicOption = relevantThematic
    ? [relevantThematic]
    : projectThematics;
  if (relevantThematic && parentsArray.length > 1) {
    return constructThematicLevel(
      relevantThematic.values,
      _.drop(parentsArray),
      currentDepth,
    );
  }

  if (!relevantThematic && currentDepth > 1) {
    return false;
  }
  return currentThematicOption.map(projectThematic => ({
    allowAny: true,
    id: projectThematic.id,
    type: 'thematics',
    label: projectThematic.label,
    ...(!_.isEmpty(projectThematic.values) && {
      subOptions: constructThematicLevel(
        projectThematic.values,
        _.drop(parentsArray),
        currentDepth,
      ),
    }),
    category: CATEGS[currentDepth],
  }));
}

export function transformThematicsToOptions(thematics, projectThematics) {
  if (_.isEmpty(thematics)) return null;
  const result = [];
  thematics.forEach(thematic => {
    let { excluded, ...rest } = thematic
    const parents = _.values(rest);
    const option = constructThematicLevel(
      projectThematics,
      parents,
    )[0];
    if (option) {
      option.excluded = excluded;
      result.push({
        option,
        parents: ['thematics', ..._.dropRight(parents)],
      });
    }
  });
  return !_.isEmpty(result) ? result : null;
}

export function transformItemFiltersToSettings(
  settings,
  projectThematics,
  isNestedType = false,
  t = null,
) {
  const resultFilters = {
    filters: {
      thematic: transformThematicsToOptions(
        settings.thematics,
        projectThematics,
      ),
      normal: transformCriteriaKeysToOptions(
        settings.criteriaKeys,
        isNestedType,
        t,
      ),
      related: null,
    },
    projectId: null,
    dateRange: settings.range,
  };
  return resultFilters;
}
