import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { StyledQModal, Navigation, StyledQButton } from './_Components/index';

class AddModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
    };
  }

  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1,
    });
  };

  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };

  resetModale = () => {
    this.props.closeModal(!this.props.isOpen);
    this.setState({
      step: 0,
    });
  };

  cancelCreateModale = () => {
    this.resetModale();
    this.props.onCancel();
  };

  validateColumnCreation = () => {
    this.resetModale();
    this.props.onSave();
  };

  getFooter(config, currentStep) {
    const { steps, cancelButton, validateButton } = config;
    const { t } = this.props;
    return (
      <Navigation>
        <StyledQButton hide={!steps[currentStep - 1]} onClick={this.prevStep}>
          {steps[currentStep].prevButtonText || t('Prev. step')}
        </StyledQButton>
        <StyledQButton onClick={this.cancelCreateModale}>
          {cancelButton.text}
        </StyledQButton>
        {steps[currentStep + 1] && (
          <StyledQButton hide={!steps[currentStep + 1]} onClick={this.nextStep}>
            {steps[currentStep].nextButtonText || t('Next step')}
          </StyledQButton>
        )}
        {!steps[currentStep + 1] && (
          <StyledQButton
            hide={steps[currentStep + 1]}
            onClick={this.validateColumnCreation}
            disabled={validateButton.disabled}
          >
            {validateButton.text}
          </StyledQButton>
        )}
      </Navigation>
    );
  }

  render() {
    const { step } = this.state;
    const { modalConfig } = this.props;

    return (
      <StyledQModal
        footer={this.getFooter(modalConfig, step)}
        isOpen={this.props.isOpen}
        onClose={this.closeModal}
        title={this.props.title}
        subtitle={this.props.subtitle}
        config={this.props.config}
      >
        {modalConfig.steps[step].stepBody}
      </StyledQModal>
    );
  }
}

AddModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  config: PropTypes.objectOf(PropTypes.bool),
  modalConfig: PropTypes.objectOf(
    PropTypes.oneOfType(
      PropTypes.objectOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.objectOf(PropTypes.node)),
    ),
  ).isRequired,
};

AddModal.defaultProps = {
  isOpen: false,
  title: null,
  subtitle: null,
  config: null,
};

export default withTranslation('button')(AddModal);
