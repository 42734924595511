const menuItems = [
  {
    name: 'main',
    title: '',
    icon: '',
    hide: true,
    pages: [
      {
        name: 'Welcome !',
        id: 'main',
        href: 'main',
        hide: true,
      },
    ],
  },
  {
    name: 'global_experience',
    title: 'Global Experience',
    icon: 'MODULES_GLOBAL_EXPERIENCE',
    layout: 'top',
    pages: [
      {
        name: 'Homepage',
        id: 'homepage',
        href: '/main',
      },
      {
        name: 'Global Results',
        id: 'global-results',
        href: '/global-results',
      },
      // {
      //   name: 'Thematics by emotions',
      //   id: 'thematics-by-emotions',
      //   href: 'thematics-by-emotions',
      // },
      // {
      //   name: 'Pain & Best points',
      //   id: 'page4',
      //   href: '',
      // },
      // {
      //   name: 'Other thematics',
      //   id: 'page6',
      //   href: '',
      // },
      // {
      //   name: 'Details by verbatim',
      //   id: 'page7',
      //   href: '',
      // },
      {
        name: 'Thematics overview',
        id: 'thematics-overview',
        href: '/thematics-overview',
      },
      {
        name: 'Verbatim list',
        id: 'verbatim-list',
        href: '/verbatim-list',
      },
      {
        name: 'Data by filters - Global',
        id: 'global-data-by-filters',
        href: '/global-data-by-filters',
      },
      // {
      //   name: '',
      //   id: 'divider',
      // },
      // {
      //   name: 'Satisfaction (demo)',
      //   id: 'satisfaction',
      //   href: 'satisfaction',
      // },
      // {
      //   name: 'Reporting (dev)',
      //   id: 'reporting',
      //   href: 'reporting',
      // },
      // {
      //   name: 'Sensations (coming soon)',
      //   id: 'page3',
      //   href: '',
      // },
      // {
      //   name: 'Settings',
      //   id: 'setting',
      //   href: '',
      //   icon: 'INTERFACE_GEAR',
      // },
    ],
  },
  {
    name: 'people',
    title: 'People',
    icon: 'MODULES_PEOPLE',
    pages: [
      {
        name: 'Synthetic view - Staff',
        id: 'people-synthetic-view',
        href: '/people-synthetic-view',
      },
      {
        name: 'Data by filters - Staff',
        id: 'people-data-by-filters',
        href: '/people-data-by-filters',
      },
      // {
      //   name: 'Staff roles',
      //   id: 'page2',
      //   href: '',
      // },
      // {
      //   name: 'Alerts on staff',
      //   id: 'alerts',
      //   href: 'alerts',
      // },
      // {
      //   name: '',
      //   id: 'divider',
      // },

      // {
      //   name: 'Settings',
      //   id: 'setting',
      //   href: '',
      //   icon: 'INTERFACE_GEAR',
      // },
    ],
  },
  {
    name: 'place',
    title: 'Place',
    icon: 'MODULES_PLACE',
    pages: [
      // {
      //   name: 'Alerts',
      //   id: 'page3',
      //   href: '',
      // },
      {
        name: 'Synthetic view - Place',
        id: 'place-synthetic-view',
        href: '/place-synthetic-view',
      },
      // {
      //   name: '',
      //   id: 'divider',
      // },
      {
        name: 'Map view',
        id: 'world-map',
        href: '/world-map',
      },
      {
        name: 'Data by filters - Place',
        id: 'place-data-by-filters',
        href: '/place-data-by-filters',
      },
      // {
      //   name: 'Flights map view (demo)',
      //   id: 'flight-map',
      //   href: 'flight-map',
      // },
      // {
      //   name: 'Seat map (demo)',
      //   id: 'seat-map-dev',
      //   href: 'seat-map-dev',
      // },

      // {
      //   name: 'Settings',
      //   id: 'setting',
      //   href: '',
      //   icon: 'INTERFACE_GEAR',
      // },
    ],
  },
  {
    name: 'product',
    title: 'Product',
    icon: 'MODULES_PRODUCT',
    pages: [
      {
        name: 'Synthetic view - Product & Services',
        id: 'product-synthetic-view',
        href: '/product-synthetic-view',
      },
      {
        name: 'Data by filters - Product & Services',
        id: 'product-data-by-filters',
        href: 'product-data-by-filters',
      },
      // {
      //   name: 'Synthetic view',
      //   id: 'product-synthetic-view',
      //   href: 'product-synthetic-view',
      // },
      // {
      //   name: 'Brand',
      //   id: 'page2',
      //   href: '',
      // },
      // {
      //   name: 'Product',
      //   id: 'page3',
      //   href: '',
      // },
      // {
      //   name: 'Alerts',
      //   id: 'page4',
      //   href: '',
      // },
      // {
      //   name: '',
      //   id: 'divider',
      // },

      // {
      //   name: 'Settings',
      //   id: 'setting',
      //   href: '',
      //   icon: 'INTERFACE_GEAR',
      // },
    ],
  },
  {
    name: 'moments',
    title: 'Moments',
    icon: 'MODULES_MOMENTS',
    pages: [
      {
        name: 'Customer journey',
        id: 'customer-journey',
        href: '/customer-journey',
      },
      {
        name: 'Key moments',
        id: 'moments',
        href: '/moments',
      },
      {
        name: 'Data by filters - Moments',
        id: 'moments-data-by-filters',
        href: '/moments-data-by-filters',
      },
      // {
      //   name: '',
      //   id: 'divider',
      // },
      // {
      //   name: 'Settings',
      //   id: 'setting',
      //   href: '',
      //   icon: 'INTERFACE_GEAR',
      // },
    ],
  },
  {
    name: 'digital',
    title: 'Digital',
    icon: 'MODULES_DIGITAL',
    pages: [
      {
        name: 'Synthetic view - Digital',
        id: 'digital-synthetic-view',
        href: '/digital-synthetic-view',
      },
      {
        name: 'Data by filters - Digital',
        id: 'digital-data-by-filters',
        href: '/digital-data-by-filters',
      },
    ],
  },
  {
    name: 'brand_com',
    title: "Comm'",
    icon: 'MODULES_COMM',
    pages: [
      {
        name: 'Synthetic view - Comm',
        id: 'comm-synthetic-view',
        href: '/comm-synthetic-view',
      },
      {
        name: 'Data by filters - Comm',
        id: 'comm-data-by-filters',
        href: '/comm-data-by-filters',
      },
      // {
      //   name: 'Page 2',
      //   id: 'page2',
      //   href: '',
      // },
      // {
      //   name: 'Page 3',
      //   id: 'page3',
      //   href: '',
      // },
      // {
      //   name: '',
      //   id: 'divider',
      // },
      // {
      //   name: 'Settings',
      //   id: 'setting',
      //   href: '',
      //   icon: 'INTERFACE_GEAR',
      // },
    ],
  },
  // {
  //   name: 'alerts',
  //   title: 'Alerts',
  //   icon: 'MODULES_ALERTS',
  //   pages: [
  //     {
  //       name: 'Synthetic view - Alerts',
  //       id: 'alerts-synthetic-view',
  //       href: '/alerts-synthetic-view',
  //     },
  //     {
  //       name: 'Data by filters - Alerts',
  //       id: 'alerts-data-by-filters',
  //       href: '/alerts-data-by-filters',
  //     },
  //     // {
  //     //   name: '',
  //     //   id: 'divider',
  //     // },
  //     // {
  //     //   name: 'Alerts (Demo)',
  //     //   id: 'alerts',
  //     //   href: 'alerts',
  //     // },
  //     // {
  //     //   name: 'Alerts Dev',
  //     //   id: 'alerts-dev',
  //     //   href: 'alerts-dev',
  //     // },
  //     // {
  //     //   name: 'Page 2',
  //     //   id: 'page2',
  //     //   href: '',
  //     // },
  //     // {
  //     //   name: 'Page 3',
  //     //   id: 'page3',
  //     //   href: '',
  //     // },
  //     // {
  //     //   name: '',
  //     //   id: 'divider',
  //     // },
  //     // {
  //     //   name: 'Settings',
  //     //   id: 'setting',
  //     //   href: '',
  //     //   icon: 'INTERFACE_GEAR',
  //     // },
  //   ],
  // },
  {
    name: 'alerts',
    title: 'Alerts',
    icon: 'MODULES_ALERTS',
    layout: 'bottom',
    pages: [
      {
        name: 'Alerts by email',
        id: 'alerting',
        href: '/alerting'
      }
    ]
  },
  {
    name: 'insights',
    title: 'Insights',
    icon: 'MODULES_INSIGHTS',
    layout: 'bottom',
    pages: [
      {
        name: 'Insight Wall',
        id: 'insights',
        href: '/insights',
      },
      // {
      //   name: 'Actions (coming soon)',
      //   id: 'actions',
      //   href: '',
      // },
      /* {
        name: '',
        id: 'divider',
      },
      {
        name: 'Settings',
        id: 'setting',
        href: '',
        icon: 'INTERFACE_GEAR',
      }, */
    ],
  },
  {
    name: 'benchmark',
    title: 'Benchmark',
    icon: 'MODULES_BENCHMARK',
    layout: 'bottom',
    pages: [
      {
        name: 'Benchmark',
        id: 'benchmark',
        href: '/benchmark',
      },
      {
        name: 'Variance analysis',
        id: 'variance-analysis',
        href: '/variance-analysis',
      },
    ],
  },
  {
    name: 'reporting',
    title: 'Reporting',
    icon: 'MODULES_REPORTING',
    layout: 'bottom',
    pages: [
      {
        name: 'Reporting main page',
        id: 'reporting-main',
        href: '/reporting-main',
      },
      {
        name: 'Reporting',
        id: 'reporting',
        href: '/reporting',
        hide: true,
      },
    ],
  },
  /* {
    name: 'settings',
    title: 'Settings',
    icon: '',
    hide: true,
    pages: [
      {
        name: 'Quality controls',
        id: 'quality-controls',
        href: 'quality-controls',
        hide: true,
      },
      // {
      //   name: '',
      //   id: 'divider',
      // },
      // {
      //   name: 'Settings',
      //   id: 'setting',
      //   href: '',
      //   icon: 'INTERFACE_GEAR',
      // },
    ],
  }, */
];
export default menuItems;
