export const createAdapter = type => ({
  isValid: ({ option }) => option.type === type,
  transform: ({ parents, option }) => {
    const [, ...categoriesParents] = parents;
    const parentsLength = categoriesParents.length;
    categoriesParents.push(option.id);
    const transformedOption = {
      thematic: '',
      subThematic: '',
      subSubThematic: '',
    };
    categoriesParents.reverse().forEach((category, index) => {
      const length = parentsLength - index;
      let prefix = '';
      if (length > 1) {
        prefix = 'subSub';
      } else if (length === 1) {
        prefix = 'sub';
      }
      transformedOption[
        `${prefix}${length > 0 ? 'Thematic' : 'thematic'}`
        ] = category;
    });
    const result = { type, ...transformedOption, };

    if (option.excluded) return { ...result, joinSpecify: 'not' }

    return result
  },
});
