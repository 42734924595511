import React, { Fragment, Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import AddModal from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/AddModal';
import { COLUMN_DEFAULT } from 'Routes/Benchmark/BenchmarkBoard/_Components/configs/columnsConfig';
import { grayShades, themeColors, white } from 'styles/abstracts/colors';
import { createTemporaryItem } from '_Resources/Benchmark/Actions/Items/createTemporaryItem';
import { connect } from 'react-redux';
import { deleteTemporaryItem } from '_Resources/Benchmark/Actions/Items/deleteTemporaryItem';
import { saveTemporaryItem } from '_Resources/Benchmark/Actions/Items/saveTemporaryItem';
import { get } from 'lodash';
import ModalFiltersPage from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/ModalFiltersPage';
import ModalNamePage from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/ModalNamePage';
import { CONTEXT } from 'Routes/Benchmark/BenchmarkBoard/_Components/configs/globalConfig';
import { columnCollapseContent } from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/_Components/configs/CollapseContents';
import { withTranslation } from 'react-i18next';
import { BenchmarkCol } from './_Components/BenchmarkCol';

const AddBenchmarkCol = styled(BenchmarkCol)`
  color: ${grayShades.g800};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    color: ${themeColors.primary};
  }
  min-height: 100px;
`;

const Text = styled.div`
  margin-top: 0.5rem;
`;

class HeaderAddColumn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  openCreateColumnModal() {
    this.setState({ isOpen: !this.state.isOpen });
    this.props.dispatchCreateColumn({
      settings: COLUMN_DEFAULT,
      id: null,
      context: CONTEXT.COLUMN,
    });
  }

  render() {
    const { isOpen } = this.state;
    const { columnWidth, t } = this.props;
    const columnTitle = get(this.props, ['temporaryColumn', 'title']);
    const columnSettings = get(this.props, ['temporaryColumn', 'settings']);

    const MODAL_CONTENT_CONFIG = {
      cancelButton: {
        text: t('button:Cancel'),
      },
      validateButton: {
        text: t('button:Create this column'),
        disabled: !columnTitle,
      },
      steps: [
        {
          title: 'Step 0',
          stepBody: (
            <ModalFiltersPage
              stepTitle={t('Choose filters for this column')}
              columnCollapseContent={columnCollapseContent}
              context={CONTEXT.COLUMN}
              currentSettingsFilters={columnSettings}
            />
          ),
          nextButtonText: t('button:Next step'),
        },
        {
          title: 'Step 1',
          stepBody: (
            <ModalNamePage
              stepTitle={t('Name your column')}
              context={CONTEXT.COLUMN}
              name={columnTitle}
            />
          ),
          prevButtonText: t('button:Prev. step'),
        },
      ],
    };

    return (
      <Fragment>
        <AddBenchmarkCol
          columnWidth={columnWidth}
          columnColor={white}
          onClick={() => this.openCreateColumnModal()}
        >
          <Text>{t('Add a column')}</Text>
        </AddBenchmarkCol>
        <AddModal
          disableOnClickOutside
          isOpen={isOpen}
          closeModal={() => this.setState({ isOpen: !isOpen })}
          onCancel={this.props.dispatchDeleteTemporaryColumn}
          onSave={this.props.dispatchSaveTemporaryColumn}
          temporarySettings={this.props.temporaryColumn}
          title={t('Add a column')}
          subtitle={t('Easy-peasy... Just follow those X steps !', {
            steps: 2,
          })}
          config={{ mentions: false, date: false }}
          modalConfig={MODAL_CONTENT_CONFIG}
        />
      </Fragment>
    );
  }
}

HeaderAddColumn.propTypes = {
  columnWidth: PropTypes.number.isRequired,
  dispatchDeleteTemporaryColumn: PropTypes.func.isRequired,
  dispatchSaveTemporaryColumn: PropTypes.func.isRequired,
  dispatchCreateColumn: PropTypes.func.isRequired,
  temporaryColumn: PropTypes.objectOf(PropTypes.node).isRequired,
};

function mapStateToProps(state) {
  return {
    temporaryColumn: get(state, ['benchmark', 'temporaryColumn']),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchCreateColumn: newState => {
      dispatch(createTemporaryItem(newState));
    },
    dispatchDeleteTemporaryColumn: () => {
      dispatch(deleteTemporaryItem(CONTEXT.COLUMN));
    },
    dispatchSaveTemporaryColumn: () => {
      dispatch(saveTemporaryItem(CONTEXT.COLUMN));
    },
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('benchmark', 'button')(HeaderAddColumn));
