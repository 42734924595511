import React from 'react';
import Login from 'Routes/UtilityRoutes/Login/Login';
import MainPage from 'Routes/Main/MainPage';
import GlobalResults from 'Routes/GlobalExperience/GlobalResults';
import ThematicsOverview from 'Routes/GlobalExperience/ThematicsOverview';
import VerbatimList from 'Routes/GlobalExperience/VerbatimList';
import BenchmarkBoard from 'Routes/Benchmark/BenchmarkBoard';
import ReportingMainPage from 'Routes/Tools/ReportingMainPage';
import Reporting from 'Routes/GlobalExperience/Reporting';
import WorldMap from 'Routes/Place/WorldMap';
import InsightsWall from 'Routes/Insights/InsightsWall';
import Settings from 'Routes/Settings';
import PeopleSyntheticView from 'Routes/People/SyntheticView';
import PlaceSyntheticView from 'Routes/Place/SyntheticView';
import ProductSyntheticView from 'Routes/Product/SyntheticView';
import DigitalSyntheticView from 'Routes/Digital/SyntheticView';
import CommSyntheticView from 'Routes/Comm/SyntheticView';
import AlertsSyntheticView from 'Routes/Alerts/SyntheticView';
import CustomerJourney from 'Routes/Moments/CustomerJourney';
import Moments from 'Routes/Moments/Moments';
import PeopleDataByFilters from 'Routes/People/DataByFilters';
import PlaceDataByFilters from 'Routes/Place/DataByFilters';
import ProductDataByFilters from 'Routes/Product/DataByFilters';
import DigitalDataByFilters from 'Routes/Digital/DataByFilters';
import MomentsDataByFilters from 'Routes/Moments/DataByFilters';
import CommDataByFilters from 'Routes/Comm/DataByFilters';
import AlertsDataByFilters from 'Routes/Alerts/DataByFilters';
import ProjectConfiguration from 'Routes/Settings/ProjectConfiguration';
import PasswordReset from 'Routes/UtilityRoutes/PasswordReset';
import GlobalDataByFilters from 'Routes/GlobalExperience/DataByFilters';
import ProjectDetails from 'Routes/Settings/ProjectDetails';
import AddData from 'Routes/Tools/AddData';
import SignUp from 'Routes/UtilityRoutes/SignUp';
import EmailValidation from 'Routes/UtilityRoutes/EmailValidation';
import UploadDataSaas from 'Routes/Tools/UploadDataSaas';
import InsertData from 'Routes/UtilityRoutes/InsertData';
import VarianceAnalysis from 'Routes/Benchmark/VarianceAnalysis';
import Alerting from 'Routes/Alerts/Alerting/';
import LandingPageMessages from "./LandingPageMessages";

const routes = [
  {
    exact: true,
    path: '/',
    component: <Login />,
  },
  {
    path: '/login',
    component: <Login />,
  },
  {
    path: '/main',
    component: <MainPage />,
  },
  {
    path: '/global-results',
    component: <GlobalResults />,
    allowPlans: [
      'emotion_start',
      'emotion_plus_monthly',
      'emotion_plus_yearly',
      'emotion_pro_monthly',
      'emotion_pro_yearly',
      'emotion_corporate',
    ],
  },
  {
    path: '/thematics-overview',
    component: <ThematicsOverview />,
    allowPlans: [
      'emotion_start',
      'emotion_plus_monthly',
      'emotion_plus_yearly',
      'emotion_pro_monthly',
      'emotion_pro_yearly',
      'emotion_corporate',
    ],
  },
  {
    path: '/verbatim-list',
    component: <VerbatimList />,
    allowPlans: [
      'emotion_start',
      'emotion_plus_monthly',
      'emotion_plus_yearly',
      'emotion_pro_monthly',
      'emotion_pro_yearly',
      'emotion_corporate',
    ],
  },
  {
    path: '/benchmark',
    component: <BenchmarkBoard />,
    allowPlans: [
      'emotion_start',
      'emotion_plus_monthly',
      'emotion_plus_yearly',
      'emotion_pro_monthly',
      'emotion_pro_yearly',
      'emotion_corporate',
    ],
  },
  {
    path: '/reporting-main',
    component: <ReportingMainPage />,
    allowPlans: [
      'emotion_start',
      'emotion_plus_monthly',
      'emotion_plus_yearly',
      'emotion_pro_monthly',
      'emotion_pro_yearly',
      'emotion_corporate',
    ],
  },
  {
    path: '/reporting',
    component: <Reporting />,
    allowPlans: [
      'emotion_start',
      'emotion_plus_monthly',
      'emotion_plus_yearly',
      'emotion_pro_monthly',
      'emotion_pro_yearly',
      'emotion_corporate',
    ],
  },
  {
    path: '/world-map',
    component: <WorldMap />,
    allowPlans: ['emotion_corporate'],
  },
  {
    path: '/insights',
    component: <InsightsWall />,
    allowPlans: ['emotion_corporate'],
  },
  {
    path: '/settings',
    component: <Settings />,
  },
  {
    path: '/message',
    component: <LandingPageMessages />
  },
  {
    path: '/people-synthetic-view',
    component: <PeopleSyntheticView />,
    allowPlans: [
      'emotion_start',
      'emotion_plus_monthly',
      'emotion_plus_yearly',
      'emotion_pro_monthly',
      'emotion_pro_yearly',
      'emotion_corporate',
    ],
  },
  {
    path: '/place-synthetic-view',
    component: <PlaceSyntheticView />,
    allowPlans: [
      'emotion_start',
      'emotion_plus_monthly',
      'emotion_plus_yearly',
      'emotion_pro_monthly',
      'emotion_pro_yearly',
      'emotion_corporate',
    ],
  },
  {
    path: '/product-synthetic-view',
    component: <ProductSyntheticView />,
    allowPlans: [
      'emotion_start',
      'emotion_plus_monthly',
      'emotion_plus_yearly',
      'emotion_pro_monthly',
      'emotion_pro_yearly',
      'emotion_corporate',
    ],
  },
  {
    path: '/digital-synthetic-view',
    component: <DigitalSyntheticView />,
    allowPlans: [
      'emotion_start',
      'emotion_plus_monthly',
      'emotion_plus_yearly',
      'emotion_pro_monthly',
      'emotion_pro_yearly',
      'emotion_corporate',
    ],
  },
  {
    path: '/comm-synthetic-view',
    component: <CommSyntheticView />,
    allowPlans: [
      'emotion_start',
      'emotion_plus_monthly',
      'emotion_plus_yearly',
      'emotion_pro_monthly',
      'emotion_pro_yearly',
      'emotion_corporate',
    ],
  },
  {
    path: '/alerts-synthetic-view',
    component: <AlertsSyntheticView />,
    allowPlans: [
      'emotion_start',
      'emotion_plus_monthly',
      'emotion_plus_yearly',
      'emotion_pro_monthly',
      'emotion_pro_yearly',
      'emotion_corporate',
    ],
  },
  {
    path: '/customer-journey',
    component: <CustomerJourney />,
    allowPlans: [
      'emotion_start',
      'emotion_plus_monthly',
      'emotion_plus_yearly',
      'emotion_pro_monthly',
      'emotion_pro_yearly',
      'emotion_corporate',
    ],
  },
  {
    path: '/moments',
    component: <Moments />,
    allowPlans: [
      'emotion_start',
      'emotion_plus_monthly',
      'emotion_plus_yearly',
      'emotion_pro_monthly',
      'emotion_pro_yearly',
      'emotion_corporate',
    ],
  },
  {
    path: '/people-data-by-filters',
    component: <PeopleDataByFilters />,
    allowPlans: [
      'emotion_start',
      'emotion_plus_monthly',
      'emotion_plus_yearly',
      'emotion_pro_monthly',
      'emotion_pro_yearly',
      'emotion_corporate',
    ],
  },
  {
    path: '/place-data-by-filters',
    component: <PlaceDataByFilters />,
    allowPlans: [
      'emotion_start',
      'emotion_plus_monthly',
      'emotion_plus_yearly',
      'emotion_pro_monthly',
      'emotion_pro_yearly',
      'emotion_corporate',
    ],
  },
  {
    path: '/product-data-by-filters',
    component: <ProductDataByFilters />,
    allowPlans: [
      'emotion_start',
      'emotion_plus_monthly',
      'emotion_plus_yearly',
      'emotion_pro_monthly',
      'emotion_pro_yearly',
      'emotion_corporate',
    ],
  },
  {
    path: '/digital-data-by-filters',
    component: <DigitalDataByFilters />,
    allowPlans: [
      'emotion_start',
      'emotion_plus_monthly',
      'emotion_plus_yearly',
      'emotion_pro_monthly',
      'emotion_pro_yearly',
      'emotion_corporate',
    ],
  },
  {
    path: '/moments-data-by-filters',
    component: <MomentsDataByFilters />,
    allowPlans: [
      'emotion_start',
      'emotion_plus_monthly',
      'emotion_plus_yearly',
      'emotion_pro_monthly',
      'emotion_pro_yearly',
      'emotion_corporate',
    ],
  },
  {
    path: '/comm-data-by-filters',
    component: <CommDataByFilters />,
    allowPlans: [
      'emotion_start',
      'emotion_plus_monthly',
      'emotion_plus_yearly',
      'emotion_pro_monthly',
      'emotion_pro_yearly',
      'emotion_corporate',
    ],
  },
  {
    path: '/alerts-data-by-filters',
    component: <AlertsDataByFilters />,
    allowPlans: [
      'emotion_start',
      'emotion_plus_monthly',
      'emotion_plus_yearly',
      'emotion_pro_monthly',
      'emotion_pro_yearly',
      'emotion_corporate',
    ],
  },
  {
    path: '/project-configuration',
    component: <ProjectConfiguration />,
    allowPlans: [
      'emotion_start',
      'emotion_plus_monthly',
      'emotion_plus_yearly',
      'emotion_pro_monthly',
      'emotion_pro_yearly',
    ],
  },
  {
    path: '/password-reset',
    component: <PasswordReset />,
  },
  {
    path: '/global-data-by-filters',
    component: <GlobalDataByFilters />,
    allowPlans: [
      'emotion_start',
      'emotion_plus_monthly',
      'emotion_plus_yearly',
      'emotion_pro_monthly',
      'emotion_pro_yearly',
      'emotion_corporate',
    ],
  },
  {
    path: '/project-details',
    component: <ProjectDetails />,
    allowPlans: [
      'emotion_start',
      'emotion_plus_monthly',
      'emotion_plus_yearly',
      'emotion_pro_monthly',
      'emotion_pro_yearly',
      'emotion_corporate',
    ],
  },
  {
    path: '/project-details/:project-name',
    component: <ProjectDetails />,
    allowPlans: [
      'emotion_start',
      'emotion_plus_monthly',
      'emotion_plus_yearly',
      'emotion_pro_monthly',
      'emotion_pro_yearly',
      'emotion_corporate',
    ],
  },
  {
    path: '/add-data',
    component: <AddData />,
    allowPlans: [
      'emotion_start',
      'emotion_plus_monthly',
      'emotion_plus_yearly',
      'emotion_pro_monthly',
      'emotion_pro_yearly',
      'emotion_corporate',
    ],
  },
  {
    path: '/email-validation',
    component: <EmailValidation />,
  },
  {
    path: '/upload-data/:uuid',
    component: <UploadDataSaas />,
    allowPlans: [
      'emotion_start',
      'emotion_plus_monthly',
      'emotion_plus_yearly',
      'emotion_pro_monthly',
      'emotion_pro_yearly',
      'emotion_corporate',
    ],
  },
  {
    path: '/insert-data',
    component: <InsertData />,
  },
  {
    path: '/sign-up',
    component: <SignUp />,
  },
  {
    path: '/variance-analysis',
    component: <VarianceAnalysis />,
  },
  {
    path: '/alerting',
    component: <Alerting />,
  },
];

export default routes;
