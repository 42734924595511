import produce from 'immer';
import {
  CONTEXT,
  TEMPORARY_STORE_TO_WATCH,
  FILTER_DIMENSIONS,
} from 'Routes/Benchmark/BenchmarkBoard/_Components/configs/globalConfig';
import { FILTERS_REMOVE_STRATEGIES } from '_Utils/filters/filterUtils';
import { rawFilterValues } from '_Utils/filters/rawFilterValues';
import { uniqBy, cloneDeep } from 'lodash';
import { mergeThematics } from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/_Components/FiltersSwitcher/FiltersUtils/ThematicsUtils';
import { isValidCriteriaKeys } from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/_Components/FiltersSwitcher/FiltersUtils/CriteriaKeysUtils';

const mergeFiltersArray = (
  initialSettings,
  filterType,
  filterValues,
  globalFilters,
) => {
  const existingFilters = initialSettings.settings.filters[filterType] || [];
  const mergedFilters = [...existingFilters, ...rawFilterValues(filterValues)];
  const validMergedFilters =
    filterType === FILTER_DIMENSIONS.NORMAL
      ? isValidCriteriaKeys(mergedFilters, globalFilters)
      : mergeThematics(mergedFilters, globalFilters);
  return produce(initialSettings, newSettings => {
    newSettings.settings.filters[filterType] = mergedFilters;
    newSettings.mergedSettings.filters[filterType] =
      validMergedFilters && uniqBy(validMergedFilters, v => v.option.id);
  });
};

export const addFilters = (state, { context, filterType, filterValues }) => {
  switch (context) {
    case CONTEXT.GLOBAL:
      // TODO : implement global filters and thematics
      return { ...state };
    case CONTEXT.COLUMN:
    case CONTEXT.CARD: {
      const currentFilters = cloneDeep(
        state.activeBenchmark.settings.globalSettings.filters[filterType],
      );
      const storeToWatch =
        TEMPORARY_STORE_TO_WATCH[context].temporarySettingsField;
      const currentSettings = state[storeToWatch];
      const newSettings = mergeFiltersArray(
        currentSettings,
        filterType,
        filterValues,
        currentFilters,
      );
      return produce(state, newState => {
        newState[storeToWatch] = newSettings;
      });
    }
    default:
      return { ...state };
  }
};

const removeFiltersFromArray = (
  initialSettings,
  { filterType, filterValues: [[filterValue], parentKey] },
  globalSettings,
) => {
  const removingStrategy =
    FILTERS_REMOVE_STRATEGIES[filterType] || FILTERS_REMOVE_STRATEGIES.default;

  const existingFilters = initialSettings.settings.filters[filterType] || [];
  const removedFiltersArray = removingStrategy(
    existingFilters,
    filterValue,
    parentKey,
  );
  return produce(initialSettings, newSettings => {
    newSettings.settings.filters[filterType] = removedFiltersArray;
    newSettings.mergedSettings.filters[filterType] =
      removedFiltersArray.length > 0
        ? isValidCriteriaKeys(removedFiltersArray, globalSettings)
        : mergeThematics(removedFiltersArray, globalSettings);
  });
};

export const removeFilters = (state, { context, filterType, filterValues }) => {
  switch (context) {
    case CONTEXT.GLOBAL:
      // TODO : implement global filters and thematics
      return { ...state };
    case CONTEXT.COLUMN:
    case CONTEXT.CARD: {
      const storeToWatch =
        TEMPORARY_STORE_TO_WATCH[context].temporarySettingsField;
      const currentSettings = state[storeToWatch];
      const newSettings = removeFiltersFromArray(
        currentSettings,
        {
          filterType,
          filterValues,
        },
        state.activeBenchmark.settings.globalSettings.filters[filterType],
      );
      return produce(state, newState => {
        newState[storeToWatch] = newSettings;
      });
    }
    default:
      return { ...state };
  }
};

export const updateFilters = (state, { filters }) => {
  if (!filters) return { ...state };
  const normal = filters.normal && rawFilterValues(filters.normal);
  const related = filters.related && rawFilterValues(filters.related);
  const thematic = filters.thematic && rawFilterValues(filters.thematic);
  const keywords = filters.keywords && rawFilterValues(filters.keywords);
  const cardsStore =
    state.activeBenchmark &&
    state.activeBenchmark.settings &&
    cloneDeep(state.activeBenchmark.settings.cards);
  const columnsStore =
    state.activeBenchmark &&
    state.activeBenchmark.settings &&
    cloneDeep(state.activeBenchmark.settings.columns);
  return produce(state, newState => {
    if (newState.activeBenchmark) {
      newState.activeBenchmark.settings.globalSettings.filters.normal = normal;
      newState.activeBenchmark.settings.globalSettings.filters.thematic = thematic;
      newState.activeBenchmark.settings.globalSettings.filters.related = related;
      newState.activeBenchmark.settings.globalSettings.filters.keywords = keywords;
    }
    const mergedThematics = item =>
      mergeThematics(
        item.settings.filters.thematic,
        newState.activeBenchmark.settings.globalSettings.filters.thematic,
      );
    cardsStore &&
      cardsStore.forEach((card, index) => {
        newState.activeBenchmark.settings.cards[
          index
        ].mergedSettings.filters.thematic = mergedThematics(card);
        newState.activeBenchmark.settings.cards[
          index
        ].mergedSettings.filters.normal = isValidCriteriaKeys(
          card.settings.filters.normal,
          normal,
        );
      });
    columnsStore &&
      columnsStore.forEach((column, index) => {
        newState.activeBenchmark.settings.columns[
          index
        ].mergedSettings.filters.thematic = mergedThematics(column);
        newState.activeBenchmark.settings.columns[
          index
        ].mergedSettings.filters.normal = isValidCriteriaKeys(
          column.settings.filters.normal,
          normal,
        );
      });
  });
};
