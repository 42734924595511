import React from 'react';
import { Row } from 'reactstrap';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import NumberOfComments from '_Layouts/Header/_Containers/HeaderTop/_Containers/NumberOfComments';
import TitleDiv from '_Layouts/Header/_Containers/HeaderTop/_Containers/TitleDiv';
import ImageProfil from '_Layouts/Header/_Containers/HeaderTop/ImageProfil';
import Link from '_Components/Link';
import ImageLogo from '_Layouts/Header/_Containers/HeaderTop/img/qemotion_logo.png';
import { purple, white, darkPurple } from 'styles/abstracts/colors';
import { spacing } from 'styles/abstracts/variables';
import { withRouter } from 'react-router-dom';
import QButton from '../../../../_Components/Buttons/QButton/QButton';

const HTContainer = styled.div`
  background: ${darkPurple};
  color: ${white};
  height: 4em;
  width: 100%;
  z-index: 5 !important;
`;

const Contained = styled(Row)`
  align-items: center;
  flex-wrap: nowrap !important;
  height: 100%;
  margin: 0;
`;

const Logo = styled.div`
  padding-left: calc(2 * ${spacing.mediumLarge}) !important;
  background: ${purple};
  width: 5em;
  height: 100%;
`;

const LogoHeader = styled(Link)`
  cursor: pointer;
`;

const TopHeader = styled.div`
  background: ${purple};
  display: inline-block;
  border-radius: 0 2em 2em 0;
  width: 100%;
`;

const OptionsHeader = styled.div`
  clear: none;
  margin-left: auto;
  margin-right: ${spacing.medium};

  button,
  button:hover,
  button:active {
    background: ${white};
    color: ${purple};
  }
`;

const QuestionMark = styled(QButton)`
  &:hover {
    background: none !important;
  }
`;

const RightContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: right;
  /* display: block; */
  width: auto;
  min-width: auto;
  padding: 0 2rem;
  /* padding-top: 1rem; */
  /* padding-bottom: 1rem; */
`;

const HeaderTop = props => {
  const { t, history } = props;
  return (
    <HTContainer id="headerTop">
      <Contained className="d-flex align-content-stretch align-items-stretch">
        <Logo className="d-flex align-content-center align-items-center">
          <LogoHeader
            onClick={() =>
              history.push('/main', { state: { toMainPage: true } })
            }
          >
            <img src={ImageLogo} alt="" />
          </LogoHeader>
        </Logo>
        <TopHeader className="d-flex align-items-center">
          <TitleDiv title={props.title} titleIcon={props.titleIcon} />
          {props.resetTour && (
            <QuestionMark
              id="questionMark"
              bgColor="white"
              outline
              color="link"
              onClick={() => props.resetTour(true)}
              icon="INTERFACE_QUESTION"
            />
          )}

          {!props.hideMentionsCount && <NumberOfComments />}
          {/* <OptionsHeader>
            <Dropdowns />
          </OptionsHeader> */}
        </TopHeader>
        <RightContainer className="">
          {/* <ItemNotDropdown nameItem={props.t('Help')} /> */}
          <ImageProfil />
        </RightContainer>
      </Contained>
    </HTContainer>
  );
};

export default withRouter(withTranslation('header')(HeaderTop));
