import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import NoDataMessage from '_Components/NoDataMessage/NoDataMessage';
import { Player, BigPlayButton, ControlBar } from 'video-react';
import { withTranslation } from 'react-i18next';

export const HintsContent = {
  HINT_EINDEX_TEMPERATURE: {
    title: 'Average E-index',
    videoLink: 'https://dv0n152gwy1ag.cloudfront.net/eindexTemperature.mp4',
    textContent: 'HINT_EINDEX_TEMPERATURE',
  },
  HINT_SAT_NPS: {
    title: 'Satisfaction & NPS',
    videoLink: 'https://dv0n152gwy1ag.cloudfront.net/satisfactionNps.mp4',
    textContent: 'HINT_SAT_NPS',
  },
  HINT_EMOTIONAL_INTENSITY: {
    title: 'Emotional intensity',
    videoLink: 'https://dv0n152gwy1ag.cloudfront.net/emotionalIntensity.mp4',
    textContent: 'HINT_EMOTIONAL_INTENSITY',
  },
  HINT_SPEECH_ENGAGEMENT: {
    title: 'Speech engagement',
    videoLink: 'https://dv0n152gwy1ag.cloudfront.net/speechEngagement.mp4',
    textContent: 'HINT_SPEECH_ENGAGEMENT',
  },
  HINT_EINDEX_EVOLUTION: {
    title: 'E-Index evolution in time scope',
    videoLink: 'https://dv0n152gwy1ag.cloudfront.net/eIndexEvolution.mp4',
    textContent: 'HINT_EINDEX_EVOLUTION',
  },
  HINT_MENTION_REPARTITION_PER_SOURCE: {
    title: 'Mention repartition per verbatim source',
    videoLink:
      'https://dv0n152gwy1ag.cloudfront.net/mentionRepartitionBySource.mp4',
    textContent: 'HINT_MENTION_REPARTITION_PER_SOURCE',
  },
  HINT_EMOTION_BREAKDOWN: {
    title: 'Emotion breakdown',
    videoLink: 'https://dv0n152gwy1ag.cloudfront.net/emotionBreakdown.mp4',
    textContent: 'HINT_EMOTION_BREAKDOWN',
  },
  HINT_EMOTION_BREAKDOWN_EVOLUTION: {
    title: 'Emotion breakdown evolution',
    videoLink:
      'https://dv0n152gwy1ag.cloudfront.net/emotionBreakdownEvolution.mp4',
    textContent: 'HINT_EMOTION_BREAKDOWN_EVOLUTION',
  },
  HINT_WORD_FREQUENCY: {
    title: 'Word frequency',
    videoLink: 'https://dv0n152gwy1ag.cloudfront.net/wordCloud.mp4',
    textContent: 'HINT_WORD_FREQUENCY',
  },
  HINT_BEST_PAIN_POINT: {
    title: 'Global best & pain points',
    videoLink:
      'https://dv0n152gwy1ag.cloudfront.net/globalBestAndPainPoints.mp4',
    textContent: 'HINT_BEST_PAIN_POINT',
  },
  HINT_TOPS_AND_FLOPS: {
    title: 'Tops & Flops',
    videoLink: null,
    textContent: 'HINT_TOPS_AND_FLOPS',
  },
  HINT_THEMATICS_BY_EMOTION: {
    title: 'Thematics by emotion',
    videoLink: null,
    textContent: 'HINT_THEMATICS_BY_EMOTION',
  },
  HINT_MENTION_COUNT: {
    title: 'Mention count',
    videoLink: 'https://dv0n152gwy1ag.cloudfront.net/mentionCount.mp4',
    textContent: 'HINT_MENTION_COUNT',
  },
  HINT_EINDEX_BADGE: {
    title: 'E-index',
    videoLink: 'https://dv0n152gwy1ag.cloudfront.net/eindexTemperature.mp4',
    textContent: 'HINT_EINDEX_BADGE',
  },
  HINT_VERBATIM_PREVIEW: {
    title: 'Verbatim preview',
    videoLink:
      'https://dv0n152gwy1ag.cloudfront.net/mentionPreviewPerEmotion.mp4',
    textContent: 'HINT_VERBATIM_PREVIEW',
  },
  HINT_DETAILED_VERBATIM_BUBBLE: {
    title: 'Analyzing detailed Verbatim information',
    videoLink: 'https://dv0n152gwy1ag.cloudfront.net/VerbatimBubble.mp4',
    textContent: 'HINT_DETAILED_VERBATIM_BUBBLE',
  },
  HINT_MENTION_BREAKDOWN: {
    title: 'Mention breakdown',
    videoLink: null,
    textContent: 'HINT_MENTION_BREAKDOWN',
  },
  HINT_SATISFACTION_CARD: {
    title: 'Satisfaction',
    videoLink: 'https://dv0n152gwy1ag.cloudfront.net/satisfactionNps.mp4',
    textContent: 'HINT_SATISFACTION_CARD',
  },
  HINT_RECOMMENDATION_CARD: {
    title: 'Recommendation',
    videoLink: 'https://dv0n152gwy1ag.cloudfront.net/satisfactionNps.mp4',
    textContent: 'HINT_RECOMMENDATION_CARD',
  },
  HINT_SYNTHETIC_RADAR_BAR_THEMATIC: {
    title: 'Categories and Subcategories preview',
    videoLink: null,
    textContent: 'HINT_SYNTHETIC_RADAR_BAR_THEMATIC',
  },
  HINT_EMOTIONAL_INTENSITY_AND_EMOTIONAL_NORMS: {
    title: 'E_index and affective norms',
    videoLink: null,
    textContent: 'HINT_EMOTIONAL_INTENSITY_AND_EMOTIONAL_NORMS',
  },
  HINT_DATA_BY_FILTERS: {
    title: 'Data by filters',
    videoLink: 'https://dv0n152gwy1ag.cloudfront.net/DataByFiltertutorial.mp4',
    textContent: 'HINT_DATA_BY_FILTERS',
  },
  HINT_VARIANCE_ANALYSIS_INTRODUCTION: {
    title: 'Introduction',
    videoLink: null,
    textContent: 'HINT_VARIANCE_ANALYSIS_INTRODUCTION',
  },
  HINT_VARIANCE_ANALYSIS_TONALITY_VOLUME_EFFECTS: {
    title: 'Volume & Tonality effects',
    videoLink: null,
    textContent: [
      'HINT_VARIANCE_ANALYSIS_VOLUME_EFFECT',
      'HINT_VARIANCE_ANALYSIS_TONALITY_EFFECT',
    ],
  },
  HINT_VARIANCE_ANALYSIS_MIX_EFFECT: {
    title: 'Mix effect and other indicators',
    videoLink: null,
    textContent: [
      'HINT_VARIANCE_ANALYSIS_MIX_EFFECT',
      'HINT_VARIANCE_ANALYSIS_OTHER_INDICATORS',
    ],
  },
  HINT_ENCHANTING_WOW: {
    title: 'Enchanting points and wow effect',
    videoLink: null,
    textContent: 'HINT_ENCHANTING_WOW',
  },
  HINT_STORIES: {
    title: 'Customer stories',
    videoLink: null,
    textContent: 'HINT_STORIES',
  },
  HINT_IRRITANTS: {
    title: 'Irritants',
    videoLink: null,
    textContent: 'HINT_IRRITANTS',
  },
  HINT_CHURN: {
    title: 'Churn',
    videoLink: null,
    textContent: 'HINT_CHURN',
  },
  HINT_MENTION_BREAKDOWN_BY_CATEGORY: {
    title: 'Mention breakdown by category',
    videoLink: null,
    textContent: 'HINT_MENTION_BREAKDOWN_BY_CATEGORY',
  },
  HINT_HORIZONTAL_BENCHMARK_GRAPH: {
    title: 'Comparative cascade view',
    videoLink: null,
    textContent: 'HINT_HORIZONTAL_BENCHMARK_GRAPH',
  },
};

const HintText = styled.div`
  margin-top: 2rem;
  & > p {
    margin-bottom: 1rem;
  }
`;

class Hint extends React.Component {
  render() {
    const { title, videoLink, textContent } = this.props.hint;
    return (
      <div className="my-3">
        {videoLink || textContent ? (
          <div>
            {title && (
              <h6 className="font-weight-bold text-center">
                {this.props.t(`card:${title}`)}
              </h6>
            )}
            {videoLink && (
              <Player
                playsInline
                // poster="/assets/poster.png"
                src={videoLink}
              >
                <BigPlayButton position="center" />
                <ControlBar className="my-class" />
              </Player>
            )}
            {textContent && (
              <>
                <HintText
                  dangerouslySetInnerHTML={{
                    __html: Array.isArray(textContent)
                      ? textContent.map(text => this.props.t(text)).join('')
                      : this.props.t(textContent),
                  }}
                />
              </>
            )}
          </div>
        ) : (
          <NoDataMessage
            mainTitle="Oops !"
            secondText="There's no help available for this subject !"
          />
        )}
      </div>
    );
  }
}

Hint.propTypes = {
  hint: PropTypes.objectOf(PropTypes.string, PropTypes.node, PropTypes.element),
};

Hint.defaultProps = {
  hint: { title: null, videoLink: null, textContent: null },
};

export default withTranslation(['hints', 'card'])(Hint);
